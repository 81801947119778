.wwd-main{
    width: 90%;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin: auto;
    margin-top: -50px;
    box-shadow: rgba(13, 14, 13, 0.2) 0px 7px 29px 0px;
    padding-bottom: 30px;
}
.wwd-cards{
    width: 90%;
    height: 80%;
    background-color: rgb(255, 255, 255);
    margin: auto;
}
.box{
  height: 315px;
}

/* ////// */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

::selection{
  background: rgba(23,162,184,0.3);
}
.wrapper{
  max-width: 100%;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}
.wrapper .box{
  background: #fff;
  width: calc(25% - 10px);
  padding: 25px;
  border-radius: 3px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
  
}
.wrapper .box i.quote{
  font-size: 20px;
  color: #17b84a;
}
.wrapper .box .content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.box .info .name{
  font-weight: 600;
  font-size: 17px;
}
.box .info .job{
  font-size: 16px;
  font-weight: 500;
  color: #17a2b8;
}
.box .info .stars{
  margin-top: 2px;
}
.box .info .stars i{
  color: #17b81c;
}
.box .content .image{
  height: 75px;
  width: 75px;
  padding: 3px;
  background: #25af27;
  border-radius: 50%;
}
.content .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
}
.box:hover .content .image img{
  border-color: #fff;
}

@media (max-width: 1045px) {
  .wrapper .box{
    width: calc(50% - 10px);
    margin: 10px 0;
  }
}
@media (max-width: 702px) {
  .wrapper .box{
    width: 100%;
  }
}


    
