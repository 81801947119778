.cards {
    margin: 0 auto;
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-auto-rows: auto;
    gap: 20px;
    font-family: sans-serif;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: rgb(255, 255, 255);
}


.cards * {
    box-sizing: border-box;
}

.card__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
   border-style: none;
   height: 225px;
}

.card__content {
    line-height: 1.5;
    font-size: 0.9em;
    padding: 15px;
    background: #fafafa;
    border-style: none;
    /* display: none; */
    height: 230px;

}

.card__content > p:first-of-type {
    margin-top: 0;
}

.card__content > p:last-of-type {
    margin-bottom: 0;
}

.card__info {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555555;
    background: #eeeeee;
    font-size: 0.8em;
   border-style: none;
    
}

.card__info i {
    font-size: 0.9em;
    margin-right: 8px;
}

.card__link {
    color: #64968c;
    text-decoration: none;
}

.card__link:hover {
    text-decoration: underline;
}
.Spices-head{
    width: 90%;
    height: 50px;
    background-color: black;
    margin-top:20px;
    border-style: none;
    margin-left: 5%;
    color: antiquewhite;
    text-align: center;
    border-radius: 3px;
}

@media only screen and (min-width: 700px ){
.about-block{
    display: flex;
}
.contact-icon-mobile{
    display:"block"
}
.contact-icon-desktop{
    display: none;
}
}
    

.contact-icon-mobile{
    display:"none"
}
.contact-icon-desktop{
    display: block;
}
.card-in{
    border: 2px solid #ebb4b4;
    padding:20px;
    border-radius: 4px;
    text-align: center;

}