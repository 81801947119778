.Slider{
    width: 100%;
    height: 82vh;
    /* background-color: blue; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    
    
     


}
.Slider-buttons h2{
    background-color: rgb(72, 70, 70);
    margin-top: -302px;
    border-radius: 5px;
    font-size: 20px;
    
}

@media only screen and (min-width: 800px ){

.Slider{
    background-size:500px;
    
}
.Slider-buttons h2{
    font-size: 40px;
}

}
    

.Slider-buttons{
    margin: auto;
    width: 96%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    opacity: 1.0;
    margin-top: -82vh;
   
}