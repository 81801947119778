.testimonial-head{
    width: 90%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    margin-top: 20px;
    border-style: none;
    margin-left: 5%;
    color: rgb(0, 0, 0);
    text-align: center;
    border-radius: 3px;
}
.testimonial-main{
    width: 100%;
    height: 300px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.testimonial-box{
    width: 300px;
    height: 250px;
    transition: width 0.7s;
    padding-left: 95px;

    
}
.testimonial-box img{
    width: 30%;
    height: 100%;
}
@media only screen and (max-width: 700px ){
    .testimonial-box img{
        width: 90%;
        height: 100%;
    }
}
/* ////// */
.testimonial-box {
    /* border: 2px solid #ccc; */
    background-color: white;
    border-radius: 5px;
    /* padding: 16px; */
    margin: 16px 0;
    overflow:hidden;
  }
  
  .testimonial-box::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .testimonial-box img {
    float: left;
    margin-right: 20px;
    border-radius: 100%;
  }
  
  .testimonial-box span {
    font-size: 20px;
    margin-right: 15px;
  }
  
  /* @media (max-width: 500px) { */
    .testimonial-box {
        text-align: center;
    }
    .testimonial-box img {
        margin: auto;
        float: none;
        display: block;
        width: 50px;
        height: 70px;
    }
  /* } */

  @media (max-width: 500px) {
    .testimonial-box p {
      font-size: 0.6rem;
    }
    .testimonial-box h4 {

    font-size: 0.8rem;
    }
  }
  .testimonial-box p {
    font-size: 0.6rem;
  }
  .testimonial-box h4 {

  font-size: 0.8rem;
  }