.chooseus-head {
    width: 90%;
    height: 50px;
    background-color: black;
    margin-top: 20px;
    border-style: none;
    margin-left: 5%;
    color: antiquewhite;
    text-align: center;
    border-radius: 3px;
}
.chooseus-row{
    width: 90%;
    height: auto;
   
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between ;
    padding: 20px 30px 30px 30px;
}
.chooseus-box{
    width: 31%;
    height: 200px;
    background-color: #e8e8dd;
    margin-top: 20px;
    text-align: center;
    border-radius: 24px;
    padding-top: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
    


}
.chooseus-box:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chooseus-box h3{
    margin-top: 20px;
}
@media only screen and (max-width: 700px ){
     .chooseus-row{
        display: block;
     }
.chooseus-box h3{
  font-size: 15px;
}
.chooseus-box{
    width: 100%;
}

}
