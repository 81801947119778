.header-main{
    position: sticky;
   
    top: 0px;
    z-index: 5;
}
.main{
    width: 90hh;
    height: 10vh;
    /* background-color: #28282B;  */
    background-color: #24262b;
    /* background-color: rgb(250, 181, 78);  */

    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
   
    top: 0px;
    z-index: 5;

}
.logo{
  
  width: 30%;
  height: 260px;
    
}

.logo-image{
width: 100%;
height: 100%;
}

@media only screen and (max-width: 800px) {
    .logo-image{
        width: 100%;
        height: 100%;
        }
        .logo{
            width: 70%;
        }
  }

  .pannel{
    width: 100%;
    min-height: 100vh;
    background-color: #28282B; 

    display: none;
    padding-bottom: 200px;
  
    /* position: sticky; */
    /* margin-left: -60%; */
    /* margin-top: -100vh; */
  }
  .closeburger{
    display: none;
  }
.nav-bar{
    width: 80%;
    min-height: 100%;
    /* background-color: red; */
    margin: auto;

}
.nav-bar{
    
    padding:20px;
}
.nav-bar ul{
    margin-top:50px;
}
.nav-bar ul li{
    
    list-style-type: none;
    
}
.nav-bar ul li a{
   font-size: 20px;
   font-weight: 300;
   color:#ffffff;
   text-decoration: none;
   line-height: 2.7rem;
}
.nav-bar ul li a:hover{
   color:#0caf4d;

}
#close:hover{
    color:#ffffff;
}
/* // */

@media only screen and (min-width: 801px) {
    .closeburger{
        display: none;
    }
    .openburger{
        display: none;
    }
}
@media only screen and (max-width: 801px) {
  .desktopMenu{
    display: none;
  }
}

.liabc {
    display: inline-block;
    position: relative;
  color: #0caf4d;
 
  }
  
  .liabc:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: 
    #0caf4d;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .liabc:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #242323;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  .dropdown-content{
    display: none;
  }
  
  .liabc:hover .dropdown-content {
    display: block;
  }