.upper-header{
    width: 100%;
    height: 40px;
    background-color: #424341;
    display: flex;

}
.upper-left{
   
    width: 60%;
    padding-left: 30px;
    display: flex;
   padding-top: 5px;
}

.upper-right{
   
    width: 40%;
    padding-right: 30px;
  margin-top: 5px;
    text-align: right;
    
}
a{
    text-decoration: none;
}
@media screen and (max-width: 970px){
.upper-header{
    display: none;
}
}
