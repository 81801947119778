/* .About-main{
    width: 100%;
    height: auto;
    background-color: #28282B;
    display:grid;
    grid-template-columns: 50% 50%;
}
.About-left{
    text-align: center;
    padding: 20px;
}
.About-left h1{
    color: antiquewhite;
    margin-top: 25px;
}

.About-left h5{
    color: antiquewhite;
    margin-top: 30px;
}

.About-right{
    
}
.office-image{
    background-image: url('http://drive.google.com/uc?export=view&id=1CFAhOO3oP0E4yXfpG06qv8NuPGGsEA1J');
 width: 85%;
 height: 25vh;
 background-color: yellow;
 background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    margin: auto;
    margin-top: 43px;
    
}
@media only screen and (max-width: 800px ){

    .About-main{
        background-size:500px;
        grid-template-columns: 100%;
    }
    } */


    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');
*{
	margin:0px;
	padding:0px;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
}
.section{
	margin-top: -10px;
	width: 100%;
	min-height: 100vh;
	background-color: #ddd;
}
.container{
	width: 80%;
	display: block;
	margin:auto;
	padding-top: 100px;
}

.content-section{
	float: left;
	width: 55%;
}
.image-section{
	float: right;
	width: 40%;
}
.image-section img{
	width: 100%;
	height: auto;
}
.content-section .title{
	text-transform: uppercase;
	font-size: 28px;
}
.content-section .content h3{
	margin-top: 20px;
	color:#5d5d5d;
	font-size: 21px;
}
.content-section .content p{
	margin-top: 10px;
	font-family: sans-serif;
	line-height: 1.5;
    font-size: 1.2em;
	text-align: justify;
}
.content-section .content .button{
	margin-top: 30px;
}
.content-section .content .button a{
	background-color: #3d3d3d;
	padding:12px 40px;
	text-decoration: none;
	color:#fff;
	font-size: 25px;
	letter-spacing: 1.5px;
}
.content-section .content .button a:hover{
	background-color: #a52a2a;
	color:#fff;
}
.content-section .social{
	margin: 40px 40px;
}
.content-section .social i{
	color:#a52a2a;
	font-size: 30px;
	padding:0px 10px;
}
.content-section .social i:hover{
	color:#3d3d3d;
}
.image-section{
    margin-top: 100px;
}
@media screen and (max-width: 768px){
	.container{
	width: 80%;
	display: block;
	margin:auto;
	padding-top:50px;

}
.content{
    text-align: center;
}
.image-section{
    margin-top: 20px;
}
.content-section{
	float:none;
	width:100%;
	display: block;
	margin:auto;
}
.image-section{
	float:none;
	width:100%;
	
}
.image-section img{
	width: 100%;
	height: auto;
	display: block;
	margin:auto;
}
.content-section .title{
	text-align: center;
	font-size: 19px;
	
}
.content-section .content .button{
	text-align: center;
}
.content-section .content .button a{
	padding:9px 30px;
}
.content-section .social{
	text-align: center;
}

}

@media screen and (max-width: 768px){
.section{
	padding-bottom: 70px;
}
}












