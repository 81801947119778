.slick-dots {
  bottom: 25px;
  color:"white";
  font-size: 20px;
  display: block;
}
.slick-dots li button:before {
 
  font-size: 20px;
  opacity: 1;
  color: white;

}
.slick-dots li button:after {
  color: greenyellow;
}
.ppp{
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .ppp{
    font-size: 20px;
  }
}