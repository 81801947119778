.member-head {
    width: 90%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    margin-top: 20px;
    border-style: none;
    margin-left: 5%;
    color: rgb(9, 9, 9);
    text-align: center;
    border-radius: 3px;
}
.member-main{
    width: 100%;
    height: 300px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.member-box{
    width: 0%;
    height: 250px;
    /* transition: width 0.7s; */
    transition: all 0.7s ease;

    
}
.member-box img{
    width: 25%;
    height: 100%;
}
@media only screen and (max-width: 700px ){
    .member-box img{
        width: 90%;
        height: 80%;
    }
}